<template>
    <News></News>
</template>

<script>
import News from '@/components/Mobile/News/News.vue'

export default {
    name: 'ApplicationNewsView',
    components: {
        News,
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
    mounted() {
    },
}
</script>
